
import AddressPicker from '@/components/address_picker'
import PageNavbar from '@/components/page_navbar'

export default {
  components: { AddressPicker, PageNavbar }

  computed: {
    debtor_registration_required: ->
      @$store.getters['account/debtor_registration_required']

    supplier_options: ->
      @$store.state.suppliers.all.map (supplier) =>
        {
          value: supplier.id,
          text: supplier.name,
          disabled: supplier.id in @debtorcards_ids
        }

    debtorcards_ids: ->
      (card.supplier_id for card in @$store.state.account.current.debtor_cards)

    debtor_cards: ->
      @$store.state.account.current?.debtor_cards

    debtor_registration_form_invalid: ->
      @id_number.length <= 0 || @business_address_1.length <= 1
  }

  created: ->
    @$store.dispatch('suppliers/fetch')

  data: -> {
    id_number: ''
    business_address_1: ''
    business_address_2: ''
    business_postal_code: ''
    debtorcard_supplier: null
    loading: false
    status_classes: { pending: 'yellow', accepted: 'green', rejected: 'gray' }
  }

  methods: {
    debtorcard_status_class: (status) ->
      classname = 'r---pill--'
      classname += @status_classes[status]
      classname

    is_last_sibling: (index, total) ->
      index == total - 1

    debtorcard_status: (status) ->
      @$t("debtorcard_label_status_#{status}")

    request_address: ->
      @$store.commit('address_picker/open', null)

    set_address: (address) ->
      @business_address_1 = address.formatted
      @business_postal_code = address.postal_code
      @$refs.address.blur()

    submit_debtor_registration: ->
      # if @$refs['debtor_registration'].validate()
      @$store.dispatch("account/update", { id_number: @id_number, business_address_1: @business_address_1 })
      .then (response) =>
        if not !!@$store.state.account.errors
          @$store.dispatch('layout/notify', @$t('popup_account_debtor_fields_complete'))
      .catch (error) ->
        console.error 'submit_debtor_registration', error

    request_debtorcard: ->
      @loading = true
      @$store.dispatch('account/request_debtorcard', @debtorcard_supplier)
      .then (response) =>
        if not !!@$store.state.account.errors
          @$store.dispatch('layout/notify', @$t('popup_account_debtor_card_request'))
      .catch (error) ->
        console.error 'request_debtorcard', error
      .finally (req) =>
        @loading = false

    show_topup_detail: (supplier_id) ->
      supplier = @$store.getters['suppliers/find'](supplier_id)
      debtorcard = @$store.getters['account/find_debtorcard'](supplier_id)
      supplier && debtorcard && @$store.commit('layout/show_debtorcard_topup_detail', {
        supplier: supplier,
        card: debtorcard
      })
  }
}
